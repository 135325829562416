import React from "react"

const TeamForm = () => {
    return(
        <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" action="/thanks" >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
                <div className="input-area">
                  <input
                    type="text"
                    name="name"
                    aria-label="Name"
                    required
                    autoComplete="off"
                  />
                  <label className="label-name" for="name">
                    <span className="content-name">Name</span>
                  </label>
              </div>

              <div className="input-area">
                <input
                  type="email"
                  name="email"
                  aria-label="Email"
                  required
                  autoComplete="off"
                />
                <label className="label-name" for="email">
                  <span className="content-name">Email</span>
                </label>
              </div>

              <div className="input-area">
                <label className="label-team" for="team">
                  <span className="content-name">Team Interested In</span>
                </label>
                <select className="ddl" name="team[]" id="team" required>
                  <option value="" selected disabled hidden>Team Interested In:</option>
                  <option value="media">Media</option>
                  <option value="prayer">Prayer</option>
                  <option value="sound-worship">Sound/Worship</option>
                  <option value="welcome">Welcome Team</option>
                  <option value="welcome">Kids Ministry</option>
                </select>
              </div>

              <div className="input-area">
                <textarea
                  type="text"
                  name="message"
                  rows="5"
                  required
                  autoComplete="off"
                  aria-label="Message"
                />
                <label className="label-name" for="message">
                  <span className="content-name">Message</span>
                </label>
              </div>

              <div className="input-area button-area">
                <button 
                  label="Send Contact Form" 
                  type="submit" 
                  className="button"
                  >
                    Submit
                  </button>
                
              </div>
            </form>
    )
}
export default TeamForm