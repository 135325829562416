import React from "react"
import TeamForm from "../Teams/teamform"
import { motion } from "framer-motion"
import Hands from "../../images/svg/hands.svg"

const TeamsBanner = () => {
    const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 20 },
      }

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ ease: "easeOut", duration: 0.8, delay: 1.2 }}
        >
          <div className="flex-container padding-top-bottom-120">
            <div className="container teams-headline small text-color-black">
                <h2 className="headline-xxxxl">
                    <span className="hidden-box d-block block-back">
                        JOIN A TEAM
                    </span>
                </h2>
                <p className="subhead-xxs block-back">
                  We are also looking for people to help serve in any capacity! Fill out the form below to get connected and someone will reach out to you shortly.
                </p>
                <Hands style={{filter:`invert(0.4)`}} />
            </div>
            <TeamForm />
          </div>
        </motion.div>
    )
}

export default TeamsBanner