import * as React from "react"
import Layout from "../components/layout"
import TeamsBanner from "../components/Teams/teamsbanner"

const Teams = () => {
  return (
    <Layout>
    <TeamsBanner />
    </Layout>
  )
}

export default Teams